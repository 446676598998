import styled from '@emotion/styled';
import { mediaMin, breakpoints } from 'styles';

export const FooterLinkWrapper = styled.section`
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: -200px;
  padding: 20px 0;
  z-index: 99;
  background: white;

  h1 {
    font-size: 12px;
    font-weight: 400;

    ${mediaMin(breakpoints.tabletM)} {
      font-size: 14px;
    }

    a {
      color: rgba(66, 74, 92, 0.5);
      text-decoration: none;
      position: relative;

      &::before {
        content: attr(data-prefix) ' ';
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
