/* eslint-disable no-console */
import { useState, useEffect, useMemo, useCallback } from 'react';
import { createQRCodeOptionsDTO } from 'api/qrCode/entities';
import { useQrCodeGeneratorContext } from 'providers/QRCodeGenerator';
import { QRCodeDTO } from 'api/qrCode/types';
import { encodeSVGStringToBase64 } from 'utils';
import { ImageTypeEnum } from 'types';

type UseQrCodeInfo = {
  isLoading: boolean;
  error: boolean;
  qrCodeTemplate: string;
  download: (fileType?: ImageTypeEnum) => void;
};

export const useQrCode = (qrCode: QRCodeDTO): UseQrCodeInfo => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [retry, setRetry] = useState<number>(3);
  const [qrCodeTemplate, setQRCodeTemplateResult] = useState<string>('');

  const qrCodeGeneratorContext = useQrCodeGeneratorContext();

  const download = useCallback(
    (fileType: ImageTypeEnum = ImageTypeEnum.SVG) => {
      qrCodeGeneratorContext?.download(
        createQRCodeOptionsDTO({ ...qrCode.qrDesign, height: 210, width: 210 }),
        qrCode.id,
        fileType
      );
    },
    [qrCode.id, qrCode.qrDesign, qrCodeGeneratorContext]
  );

  useEffect(() => {
    setIsLoading(true);

    if (success || retry === 0) {
      setIsLoading(false);
      return;
    }

    qrCodeGeneratorContext
      ?.buildQRCode(createQRCodeOptionsDTO({ ...qrCode.qrDesign, height: 210, width: 210 }))
      .then((qrCode) => {
        setQRCodeTemplateResult(encodeSVGStringToBase64(qrCode));
        setSuccess(true);
      })
      .catch((err) => {
        setError(true);
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        setRetry((prevState) => prevState - 1);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrCodeGeneratorContext, retry]);

  return useMemo(
    () => ({
      isLoading,
      qrCodeTemplate,
      error,
      download,
    }),
    [isLoading, qrCodeTemplate, error, download]
  );
};
