import { QRCodeTypeToUrlMapping } from 'types/qrCodes';
import { LINKS } from 'constants/constants';
import { DEFAULT_LOCALE } from 'constants/languages';

export const BASE_URL = 'https://myqrcode.com';

export const ROUTES = [
  '',
  LINKS.PRICES,
  LINKS.FAQ,
  LINKS.CONTACT_US,
  /*
  LINKS.BUILDER_BASE,
  ...(Object.keys(QRCodeTypeEnum) as Array<keyof typeof QRCodeTypeEnum>).map(
    (key) => `${LINKS.BUILDER_BASE}/${QRCodeTypeEnum[key]}`
  ),
  */
];

export function canonicalLink(link?: string, href?: boolean, locale = DEFAULT_LOCALE): string {
  if (href) {
    return typeof window !== 'undefined' ? window.location.href : '';
  }

  const envDomain = process.env.NEXT_PUBLIC_HOST || ('' as string);

  if (locale !== DEFAULT_LOCALE) {
    return `${envDomain}/${locale}${link}`;
  }

  return envDomain + link;
}

export function languageAlternatesLinks(link: string, locales?: string[]) {
  const envDomain = process.env.NEXT_PUBLIC_HOST as string;

  if (!locales) {
    return [
      {
        hrefLang: '',
        href: envDomain + link,
      },
    ];
  }
  return [
    ...locales.map((locale) => ({
      hrefLang: locale,
      href: `${envDomain}${locale !== DEFAULT_LOCALE ? `/${locale}` : ''}${link}`,
    })),
    {
      href: `${envDomain}${link}`,
      hrefLang: 'x-default',
    },
  ];
}

export function getLinkbyQrType(type?: keyof typeof QRCodeTypeToUrlMapping): string {
  return type ? `${LINKS.BUILDER_BASE_CANONICAL}/${QRCodeTypeToUrlMapping[type]}` : LINKS.BUILDER_BASE_CANONICAL;
}

export const botUserAgent =
  /Googlebot|Google-InspectionTool|Screaming Frog|Lumar|Oncrawl|cognitiveSEO|Deep Crawl|GoogleOther|Ahrefs|Semrush|Rogerbot|YandexBot|Exabot|Swiftbot|Mediapartners-Google|AdsBot-Google|googleweblight|Storebot-Google|Google-PageRenderer|Bingbot|BingPreview|Slurp|DuckDuckBot|baiduspider|yandex|sogou|LinkedInBot|bitlybot|tumblr|vkShare|quora link preview|facebookexternalhit|facebookcatalog|Twitterbot|applebot|redditbot|Slackbot|Discordbot|WhatsApp|SkypeUriPreview|ia_archiver/i;
