import type { GetServerSideProps, GetServerSidePropsResult } from 'next';
import { NextSeo } from 'next-seo';
import { canonicalLink, languageAlternatesLinks, getLinkbyQrType } from 'utils/seo';
import { useTranslation } from 'react-i18next';

import type { QRCodeDTO } from 'api/qrCode/types';

import { QRCodeGeneratorProvider } from 'providers/QRCodeGenerator';
import { commonQRCodeApi } from 'api/qrCode/apis';
import QrCodeScan from 'components/QrCodeScan';
import { qrCodeService } from 'libs/core/qr-codes/service/qr-codes.service';
import { LINKS, DISABLED_ROUTE_URL, BASE_LINKS } from 'constants/constants';
import i18n from 'config/i18n';
import { useRouter } from 'next/router';

export type QRCodePageProps = {
  qrCode: QRCodeDTO;
};

export const getServerSideProps: GetServerSideProps<QRCodePageProps, { id: string }> = async (context) => {
  const params = context.params as { id: string };
  await i18n.changeLanguage(context.locale);

  try {
    const qrCode = await commonQRCodeApi.getByURI(params.id);
    if (!qrCode)
      return {
        redirect: {
          destination: DISABLED_ROUTE_URL,
          statusCode: 301,
        },
      } as GetServerSidePropsResult<QRCodePageProps>;
    return {
      props: {
        qrCode: qrCodeService.prepareQRCodeToScan(qrCode),
      },
    };
  } catch (err) {
    console.error(err);
    return {
      redirect: {
        destination: DISABLED_ROUTE_URL,
        statusCode: 301,
      },
    } as GetServerSidePropsResult<QRCodePageProps>;
  }
};

export default function QRCodePage(props: QRCodePageProps): JSX.Element {
  const { t, i18n } = useTranslation();
  const { locales } = useRouter();

  return (
    <>
      <NextSeo
        title={t('qr.scan.default.title', 'QR Code generated by My QR Code')}
        description={t(
          'qr.page.landing.seo.description',
          'QR Code Generator: convert any link, vCard, facebook, image or video into a QR code, create QR restaurant menu, track QR code scans, customize design'
        )}
        languageAlternates={languageAlternatesLinks(LINKS.QR_SCAN, locales)}
        canonical={canonicalLink(getLinkbyQrType(props.qrCode.content?.type), false, i18n.language)}
      />
      <QRCodeGeneratorProvider>
        <QrCodeScan qrCode={props.qrCode} />
      </QRCodeGeneratorProvider>
    </>
  );
}
