import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTranslationEditorContext } from 'providers/TranslationEditor';
import { canonicalLink } from 'utils/seo';
import { FooterLinkWrapper } from './styles';
import { LINKS } from 'constants/constants';

export type FooterLinkProps = {
  linkTo?: string;
  disabled?: boolean;
};

const FooterLink: FC<FooterLinkProps> = ({ linkTo, disabled }) => {
  const { t } = useTranslation();
  const { getDataAttributes } = useTranslationEditorContext();

  return !disabled ? (
    <FooterLinkWrapper className="footer-link">
      <h1>
        <a
          href={linkTo || canonicalLink('')}
          rel={linkTo?.includes(LINKS.BUILDER_BASE) ? 'nofollow' : ''}
          {...getDataAttributes('qr.seo.slogan')}
          data-prefix={t('qr.seo.slogan.prefix', 'Powered by')}
        >
          {t('qr.seo.slogan', 'My QR Code - QR Code Generator')}
        </a>
      </h1>
    </FooterLinkWrapper>
  ) : null;
};

export default memo(FooterLink);
