import styled from '@emotion/styled';
import { COLORS, mediaMax, breakpoints } from 'styles';

export const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 40px;
`;

export const Text = styled.h2`
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  color: ${COLORS.PRIMARY_TEXT};

  ${mediaMax(breakpoints.tabletXS)} {
    font-size: 18px;
    line-height: 21px;
  }
`;
