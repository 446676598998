import styled from '@emotion/styled';
import { mediaMax, mediaMaxHeightOrientation, breakpoints } from 'styles';

export const Wrapper = styled.div`
  display: grid;
  place-items: center;
  place-content: center;
`;

export const QRWrapper = styled.div`
  width: 224px;
  height: 300px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }

  ${mediaMax(breakpoints.tabletXS)} {
    width: unset;
    height: unset;
    margin-bottom: 10px;

    img {
      height: 60vw;
      width: 60vw;
      object-fit: contain;
    }
  }

  ${mediaMaxHeightOrientation(600, 'landscape')} {
    width: 180px;
    height: auto;
  }
`;

export const Container = styled.div`
  width: 600px;
  height: 600px;
  display: grid;

  ${mediaMax(breakpoints.tabletM)} {
    width: unset;
    height: unset;
    justify-content: center;
    justify-items: center;
    min-height: 95vw;
  }

  ${mediaMaxHeightOrientation(800, 'landscape')} {
    height: 90vh;
  }
  ${mediaMaxHeightOrientation(600, 'landscape')} {
    height: 80vh;
  }
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height */

  text-align: center;

  /* Main Text Color */

  color: #1e1d20;
  margin-bottom: 16px;

  ${mediaMax(breakpoints.tabletXS)} {
    display: none;
  }
`;

export const Warning = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #ef5d5d;

  ${mediaMax(breakpoints.tabletXS)} {
    font-size: 14px;
  }
`;
