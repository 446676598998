import { FC, memo } from 'react';
import { canonicalLink, getLinkbyQrType } from 'utils/seo';
import { useTranslation } from 'react-i18next';

import { useQrCode } from 'hooks/useQrCode';
import { QRCodeDTO } from 'api/qrCode/types';
import { QRWrapper } from 'components/QRCodes/PopupManager/QrPreviewPopup/styled';
import TranslationSpan from 'components/ui/TranslationSpan';
import { Container, Text } from './styled';
import { LINKS } from 'constants/constants';
import FooterLink from '../QRTemplates/shared/FooterLink';
import { getTitleByType } from '../../utils/qrCodeTypeTitles';

type QrCodeScanProps = {
  qrCode: QRCodeDTO;
};

const QrCodeScan: FC<QrCodeScanProps> = ({ qrCode }) => {
  const { qrCodeTemplate } = useQrCode(qrCode);
  const { t, i18n } = useTranslation();
  const codeType = qrCode.content?.type as string;
  const pageTitle = getTitleByType(codeType);
  const canonicalURL = codeType
    ? canonicalLink(getLinkbyQrType(qrCode.content?.type), false, i18n.language)
    : LINKS.BUILDER_BASE;

  return (
    <Container>
      <QRWrapper>
        <img src={qrCodeTemplate} draggable="false" alt={`${t('qr.scan.alt', 'Scan this')} ${pageTitle}`} />
      </QRWrapper>
      <Text>
        <TranslationSpan phrase="qr.shared.scan.qr" fallback="Scan the QR code" />
      </Text>
      <FooterLink linkTo={canonicalURL} disabled={qrCode.userSettingsJson.hideFooter} />
    </Container>
  );
};

export default memo(QrCodeScan);
