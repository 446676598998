import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTranslationEditorContext } from 'providers/TranslationEditor';

export type TranslationSpanProps = {
  phrase: string;
  fallback?: string;
  options?: Record<string, any>;
  attributes?: Record<string, unknown>;
};

const TranslationSpan: FC<TranslationSpanProps> = ({ phrase, fallback = '', options, attributes = {} }) => {
  const { t } = useTranslation();
  const { getDataAttributes } = useTranslationEditorContext();

  const text = useMemo(() => {
    if (fallback) {
      return t(phrase, fallback, options);
    }

    return t(phrase, options);
  }, [phrase, fallback, options, t]);

  return (
    <span {...attributes} {...getDataAttributes(phrase)}>
      {text}
    </span>
  );
};

export default memo(TranslationSpan);
