import { t } from 'i18next';

export const getTitleByType = (codeType: string) => {
  switch (codeType) {
    case 'url':
      return t('qr.scan.url.title', 'Link QR Code generated by My QR Code');
    case 'vCard':
      return t('qr.scan.vCard.title', 'VCard QR Code generated by My QR Code');
    case 'businessPage':
      return t('qr.scan.businessPage.title', 'Business QR Code generated by My QR Code');
    case 'app':
      return t('qr.scan.app.title', 'App QR Code generated by My QR Code');
    case 'video':
      return t('qr.scan.video.title', 'Video QR Code generated by My QR Code');
    case 'menu':
      return t('qr.scan.menu.title', 'Restaurant Menu QR Code generated by My QR Code');
    case 'facebook':
      return t('qr.scan.facebook.title', 'Facebook QR Code generated by My QR Code');
    case 'plainText':
      return t('qr.scan.plainText.title', 'Text QR Code generated by My QR Code');
    case 'wifi':
      return t('qr.scan.wifi.title', 'WiFi QR Code generated by My QR Code');
    case 'images':
      return t('qr.scan.images.title', 'Images QR Code generated by My QR Code');
    case 'socialMedia':
      return t('qr.scan.socialMedia.title', 'Social Media QR Code generated by My QR Code');
    default:
      return t('qr.scan.default.title', 'QR Code generated by My QR Code');
  }
};
